<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;">我的二维码</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="seeQRcode"
              >查看二维码</el-button
            >
            <!-- <div class="btnBox">
              <el-button class="bgc-bv" round>新增绑定</el-button>
            </div> -->
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                label="绑定日期"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                width="160px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | createTime }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="unbind(scope.row)"
                    >解除绑定</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="机构二维码"
      :visible="seeseeQRcodeLoading"
      :before-close="doClose"
      width="860px"
    >
      <div class="shell">
        <img class="l" :src="generateQRcodeImg" alt="" />
        <div class="r">
          <p>微信扫码订阅开班情况</p>
          <p>微信可接收该机构的开班情况</p>
          <p class="p2">订阅步骤</p>
          <p class="p2">1.打开微信扫一扫，扫描二维码。</p>
          <p class="p2">2.扫码关注“安知平台”公众号。</p>
          <p class="p2">
            3.关注后点击【立即绑定】，填写身份证号、姓名和手机号进行绑定。
          </p>
          <p class="p2">
            4.已经绑定的用户，点击【立即查看】，查看班级开班情况。
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seeseeQRcodeLoading = false" size="small"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "testPaperEdit",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 二维码 - src
      generateQRcodeImg: "",
      // 二维码 - loading
      seeseeQRcodeLoading: false,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch({
        url: "/biz/subscribeProject/myQrCodeBindList",
        params,
        pageNum,
      });
    },
    // 查看二维码
    seeQRcode() {
      this.$post("biz/subscribeProject/createSubscribeCompanyQRCode", {}).then(
        (res) => {
          this.seeseeQRcodeLoading = true;
          this.generateQRcodeImg = res.data;
        }
      );
    },
    // 解除绑定
    unbind(row) {
      this.$post("/biz/subscribeProject/canalCompQrCodeBind", {
        subId: row.subId,
      }).then((res) => {
        if (res.status == 0) {
          this.$message({
            message: "解绑成功",
            type: "success",
          });
          this.getData();
        }
      });
    },
    // 关闭查看二维码
    doClose(done) {
      this.seeseeQRcodeLoading = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.shell {
  width: 100%;
  overflow: hidden;
  .l {
    width: 30%;
    float: left;
  }
  .r {
    width: 70%;
    float: left;
  }
  .p2 {
    margin-top: 20px;
  }
}
</style>